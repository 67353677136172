const setFavicon = () => {
  const companyData = JSON.parse(localStorage.getItem("company"));

  if (companyData.favicon) {
    const faviconLink = document.querySelector("link[rel='icon']");
    if (faviconLink) {
      faviconLink.href = companyData.favicon;
    } else {
      const newFaviconLink = document.createElement("link");
      newFaviconLink.rel = "icon";
      newFaviconLink.href = companyData.favicon;
      document.head.appendChild(newFaviconLink);
    }
  }
};

const setPageTitle = (title) => {
  const companyData = JSON.parse(localStorage.getItem("company"));
  document.title = `${title} - ${
    companyData && companyData.short_name ? companyData.short_name : "CIB"
  }`;
};

export { setFavicon, setPageTitle };
