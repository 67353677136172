import React, { useEffect, useState } from "react";
import TransactionOTPModal from "../../../../Modals/TransactionsOTPModal";
import { NavigationButton } from "../../../../Buttons";
import CommonButton from "../../../../Buttons/CommonButton";
import FormInputField from "../../../../FormInputField";
import Label from "../../../../Label";
import TransferGifs from "../partials/TransferGifs";
import { DownArrowIcon } from "../../../../Icons";
import {
  externalTR,
  updateExternalTR,
} from "../../../../../redux/actions/TRrequest/External";
import { useDispatch } from "react-redux";
import { useDeleteDocument, useForm } from "../../../../../hooks";
import {
  FormatBalance,
  GetCurrenySymbol,
  GetValidationErrors,
} from "../../../../../helper";
import SelectBeneficiaryModal from "../../../../Modals/Beneficiary/SelectBeneficiaryModal";
import TransactionDetail from "../../../TransactionDetail";
import Review from "../../../Review";
import { verifyTROTP } from "../../../../../redux/actions/TRrequest/OTPVerification";
import CustomTransition from "../../../../CustomTransition";
import { setPageTitle } from "../../../../../helper/metaDataHelper";
import { getTransactionDetails } from "../../../../../redux/actions/Transaction/TransactionHistory";
import { setActiveAccount } from "../../../../../redux/actions/home/ActiveAccountAction";
import { useNavigate } from "react-router-dom";
import SelectAccountDropdown from "../../../../Common/SelectAccountDropdown";
import ErrorMessage from "../../../../Common/ErrorMessage";
import PreviousDocs from "../../../../Common/PreviousDocs";
import MultipleFileUpload from "../../../../MultipleFileUpload";

const ExternalTransfer = ({
  accounts,
  activeAccount,
  handlePrev,
  handleNext,
  setStep,
  step,
  setIsTransactionConfirmed,
  isTransferConfirmed,
  beneficiary,
  location,
}) => {
  const [loading, setLoading] = useState(false);
  const [trn_id, setTrnId] = useState("");
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [beneficiaryAccount, setBeneficiaryAccount] = useState(beneficiary);
  const [supportingDocument, setSupportingDocument] = useState("");
  const { documents, setDocuments, handleDeleteDocument } = useDeleteDocument(
    []
  );
  const [error, setError] = useState([]);

  const fromAccount = useForm(activeAccount);
  const amount = useForm("", "amount");
  const remarks = useForm("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setPageTitle("External Transfer");
  }, []);

  useEffect(() => {
    if (location.state && location.state.transaction) {
      // get transaction details
      const fetchTransactionDetails = async () => {
        setLoading(true);
        const data = {
          trn_id: location.state.transaction.id,
          transaction_nature: "TT",
        };
        const res = await dispatch(getTransactionDetails(data));
        if (res.success) {
          amount.setValue(res.data.amount);
          remarks.setValue(res.data.remarks);
          setBeneficiaryAccount(res.data.beneficiary);

          if (res.data.docs && res.data.docs.length > 0) {
            setDocuments(res.data.docs);
          }
        }
        setLoading(false);
      };
      fetchTransactionDetails();
    }
  }, [location.state, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeFromAccount = async (account) => {
    fromAccount.setValue(account);
    if (error || fromAccount.error) {
      setError("");
      fromAccount.setError("");
    }
  };

  const handleExternalTransfer = async () => {
    setLoading(true);
    setIsOTPModalOpen(true);
    const today = new Date();
    const formData = new FormData();
    formData.append("account_id", fromAccount.value.id);
    formData.append("beneficiary_id", beneficiaryAccount.id);
    formData.append("amount", amount.value);
    formData.append("remarks", remarks.value);
    formData.append("transaction_date", today.toISOString().split("T")[0]);

    if (supportingDocument) {
      Object.keys(supportingDocument).forEach((key) => {
        supportingDocument[key].forEach((file) => {
          formData.append("supporting_documents[]", file);
        });
      });
    }

    let res;

    try {
      if (location.state && location.state.transaction) {
        formData.append("trn_id", location.state.transaction.id);
        res = await dispatch(updateExternalTR(formData));
      } else {
        res = await dispatch(externalTR(formData));
      }

      if (res.success) {
        setTrnId(res.data.trn_id);
        setLoading(false);
        setIsOTPModalOpen(true);
        setIsTransactionConfirmed(true);
        setStep(3);
        location.state = null;
        return;
      } else if (!res.success) {
        if (res.errors) {
          setError(GetValidationErrors(res.errors));
        } else {
          setError(res.error);
        }
        setStep(2);
        setIsOTPModalOpen(false);
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setIsOTPModalOpen(false);
      setStep(2);
    }
  };

  const validateExternal = () => {
    let validate = true;

    if (
      fromAccount.value.balance <= 0 ||
      fromAccount.value.balance < amount.value
    ) {
      validate = false;
      fromAccount.setError("Insufficient balance in this account.");
      amount.setError(true);
    }

    if (amount.validate()) {
      validate = false;
      amount.setError("Please enter a valid amount.");
    }

    if (!remarks.value) {
      validate = false;
      remarks.setError("Please enter a remark.");
    }

    if (!supportingDocument && documents.length === 0) {
      validate = false;
      setError("Please upload a supporting document");
    }

    setStep(2);

    return validate;
  };

    const handleFileChange = (name, selectedFiles) => {
      setSupportingDocument((prevFiles) => {
        const updatedFiles = { ...prevFiles, [name]: selectedFiles };
        if (!selectedFiles.length) {
          delete updatedFiles[name];
        }
        return updatedFiles;
      });
      if (error) {
        setError([]);
      }
    };

  const handleContinue = () => {
    if (!validateExternal()) return;
    handleNext();
  };

  const handleViewTransaction = async () => {
    setIsOTPModalOpen(false);
    setStep(1);
    navigate("/all-transactions");
    await dispatch(setActiveAccount(fromAccount.value));
  };

  return (
    <>
      {step === 2 && (
        <div className="md:w-[400px] space-y-4">
          {error.length > 0 && (
            <ErrorMessage message={error} onClose={() => setError("")} />
          )}

          <div className="space-y-4">
            <div className="space-x-3">
              <span className="text-paragraph-text text-sm font-normal tracking-tight">
                You’re have
              </span>
              <span
                className={`text-body-text text-sm font-bold tracking-tight
                ${fromAccount.value.balance <= 0 && "text-highlighter-red"}
                `}
              >
                {FormatBalance(fromAccount.value.balance)}{" "}
                {fromAccount.value.currency_code}
              </span>
            </div>

            <div className="space-y-3">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"From account"}
              />
              <SelectAccountDropdown
                title={"From account"}
                accounts={accounts}
                slectedAccount={fromAccount.value}
                setAccount={handleChangeFromAccount}
              />

              {fromAccount.error && (
                <span className="text-highlighter-red text-sm px-2">
                  {fromAccount.error}
                </span>
              )}
            </div>

            <TransferGifs />

            <div className="space-y-3">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"To account"}
              />

              <SelectBeneficiaryModal
                setBeneficiaryAccount={setBeneficiaryAccount}
                beneficiaryAccount={beneficiaryAccount}
              >
                <FormInputField
                  label={"Beneficiary account"}
                  placeholder="Select Beneficiary account"
                  type="text"
                  readOnly={true}
                  value={beneficiaryAccount.name}
                  icon={<DownArrowIcon />}
                  error={error.accountNumberError}
                />
              </SelectBeneficiaryModal>
            </div>

            <CustomTransition show={beneficiaryAccount.id ? true : false}>
              <div className="space-y-3">
                <div>
                  <FormInputField
                    label="Amount"
                    placeholder="Enter Amount"
                    type="number"
                    {...amount}
                    currency={GetCurrenySymbol(
                      fromAccount.value.currency_code,
                      "13",
                      "13"
                    )}
                  />
                </div>
                <div>
                  <FormInputField
                    label="Remarks"
                    placeholder="Add Note"
                    type="text"
                    {...remarks}
                  />
                </div>
                <div>
                  <MultipleFileUpload
                    name="supporting_documents"
                    id="supporting_documents"
                    title={"Supporting document"}
                    onChange={handleFileChange}
                    isMultiple={true}
                    maxCount={5}
                    mandatory={true}
                    files={files}
                    setFiles={setFiles}
                  />
                  <PreviousDocs
                    documents={documents}
                    handleDeleteDocument={handleDeleteDocument}
                  />
                  {error.supportingDocument && (
                    <span className="text-highlighter-red text-sm px-2">
                      {error.supportingDocument}
                    </span>
                  )}
                </div>
              </div>
            </CustomTransition>
          </div>

          <hr className="border border-divider-border" />

          <div className="flex space-x-4">
            <NavigationButton text={"Back"} onClick={handlePrev} />
            <CommonButton
              disabled={
                !amount.value ||
                !remarks.value ||
                (!supportingDocument && documents.length === 0) ||
                !beneficiaryAccount.id
              }
              onClick={handleContinue}
            >
              Continue
            </CommonButton>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="md:w-[568px]">
          <Review
            supportingDocument={supportingDocument}
            handlePrev={handlePrev}
            handleTransaction={handleExternalTransfer}
            amount={`${amount.value} ${fromAccount.value.currency_code}`}
            message=" will be transferred to the below account details."
            isTransactionConfirmed={isTransferConfirmed}
          >
            <TransactionDetail
              label="You’re sending"
              value={`${amount.value} ${fromAccount.value.currency_code}`}
            />
            <TransactionDetail label="Nature" value="External Transfer" />

            <hr className="flex w-full border-divider-border" />
            <TransactionDetail
              label="From account"
              value={`${fromAccount.value.title}`}
            />

            <TransactionDetail
              label="Recipient account name"
              value={beneficiaryAccount.name}
            />
            <TransactionDetail
              label="Recipient account number"
              value={beneficiaryAccount.account_number}
            />

            <hr className="flex w-full border-divider-border" />

            <TransactionDetail label="Remarks" value={remarks.value} />
          </Review>
        </div>
      )}

      <TransactionOTPModal
        isLoading={loading}
        setisLoading={setLoading}
        TrnId={trn_id}
        setTrnId={setTrnId}
        isModalOpen={isOTPModalOpen}
        setIsModalOpen={setIsOTPModalOpen}
        handleTransaction={handleExternalTransfer}
        reduxaction={verifyTROTP}
        navigateUrl="/"
        handleViewTransaction={handleViewTransaction}
      />
    </>
  );
};

export default ExternalTransfer;
