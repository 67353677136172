import React from "react";
import useCompanyData from "../../hooks/useCompanyData";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const company = useCompanyData();
  
  return (
    <div className="w-full justify-center items-center inline-flex bg-body-bg">
      <div className="text-center text-paragraph-text text-xs font-medium tracking-tight">
        {currentYear} © {company.name}
      </div>
    </div>
  );
};

export default Footer;