import React, { useEffect } from "react";

const LoadingSpinner = () => {

    useEffect(() => {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }, []);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 z-[50] flex items-center justify-center">
      <div className="relative flex items-center justify-center w-28 h-28 animate-breathing">
        <span className="absolute text-3xl font-extrabold text-[#EFC660]">CIB</span>
        <div className="w-full h-full rounded-full border-[8px] border-transparent border-t-[#EFC660] animate-spin"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
