import React from "react";

const ViewSupportingDocument = ({ supportingDocument }) => {
  const openSupportingDocument = (item) => {
    const url = URL.createObjectURL(item);
    window.open(url, "_blank");
  };

  return (
    <div className="grid md:grid-cols-4 grid-cols-3">
      {supportingDocument.supporting_documents.map((doc, index) => (
        <li
          key={index}
          className="flex justify-between items-center hover:bg-option-list-hover rounded-2xl p-2"
        >
          <span
            className="text-primary-item text-sm font-medium underline cursor-pointer px-2"
            onClick={() => openSupportingDocument(doc)}
          >
            View {index + 1}
          </span>
        </li>
      ))}
    </div>
  );
};

export default ViewSupportingDocument;
