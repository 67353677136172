import { SmallButton } from "../../../../Buttons";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { GetThemeClass } from "../../../../../helper";

const SignaturePad = ({ setFormData, setError, handleClearSignature }) => {
  const signaturePadRef = useRef(null);

  const clearSignature = () => {
    signaturePadRef.current.clear();
    handleClearSignature();
  };

  const saveSignature = async () => {
    const signaturePad = signaturePadRef.current;

    if (!signaturePad.isEmpty()) {
      const canvas = signaturePad.getCanvas();

      canvas.toBlob((blob) => {
        const file = new File([blob], "signature.png", { type: "image/png" });

        setFormData((prevFormData) => ({
          ...prevFormData,
          signature_photo: file,
        }));
        setError("");
      }, "image/png");
    } else {
      setError("Signature is required");
    }
  };

  return (
    <>
      <div className="text-paragraph-text text-xs font-normal tracking-tight py-3">
        Draw signature on the below area, use your mouse/trackpad.
      </div>
      <SignatureCanvas
        ref={signaturePadRef}
        penColor={GetThemeClass("#5F4D21", "#EFC660")}
        canvasProps={{
          width: 360,
          height: 200,
          className:
            "bg-popup-bg w-full h-[232px] rounded-3xl border border-divider-border cursor-pointer text-primary-item",
        }}
        onEnd={saveSignature}
        minWidth={1} // Minimum stroke width
        maxWidth={1} // Maximum stroke width
      />
      <div className="button-container"></div>
      <div className="flex justify-between p-3">
        <SmallButton onClick={clearSignature} text={"Clear"} cancel={true} />
      </div>
      <hr className="bg-divider-border" />
    </>
  );
};

export default SignaturePad;
