import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteDocument, useForm } from "../../../../hooks";
import CancelTransactionModal from "../../../Modals/CancelTransactionModal";
import { PrimaryButton, SmallButton } from "../../../Buttons";
import ErrorMessage from "../../../Common/ErrorMessage";
import Label from "../../../Label";
import SelectAccountDropdown from "../../../Common/SelectAccountDropdown";
import FormInputField from "../../../FormInputField";
import EmptyCryptoTransactions from "../../../Empty/EmptyCryptoTransactions";
import {
  FormatBalance,
  FormatTRDate,
  GetCurrenySymbol,
  GetValidationErrors,
} from "../../../../helper";
import FormTextAreaField from "../../../FormTextAreaField";
import OrderComplete from "../../OrderComplete";
import { TransactionDetail } from "../../../Transactions";
import {
  CryptoManualBuy,
  UpdateCryptoManualBuy,
} from "../../../../redux/actions/CryptoTransaction/Buy";
import { verifyTROTP } from "../../../../redux/actions/TRrequest/OTPVerification";
import TransactionOTPModal from "../../../Modals/TransactionsOTPModal";
import { useLocation } from "react-router-dom";
import { getTransactionDetails } from "../../../../redux/actions/Transaction/TransactionHistory";
import { setActiveAccount } from "../../../../redux/actions/home/ActiveAccountAction";
import PreviousDocs from "../../../Common/PreviousDocs";
import MultipleFileUpload from "../../../MultipleFileUpload";
import ViewSupportingDocument from "../../../Common/ViewSupportingDocument";

// handle the error messages and add an api to get the max and min count for currencies from bitpace.
const ManualBuy = () => {
  const dispatch = useDispatch();
  const amount = useForm("", "amount");
  const remarksInput = useForm("");
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [error, setError] = useState("");
  const [supportingDocument, setSupportingDocument] = useState("");
  const { documents, setDocuments, handleDeleteDocument } = useDeleteDocument(
    []
  );
  const [order, setOrder] = useState({});
  const { activeAccount } = useSelector((state) => state.activeAccount);

  const clientInfo = useSelector((state) => state.client.clientInfo);
  const fiatAccounts = clientInfo.accounts.filter(
    (account) => account.is_crypto === 0 || account.is_crypto === null
  );
  const cryptoAccount = clientInfo.crypto_accounts;
  const [selectedFiatAccount, setSelectedFiatAccount] = useState(
    fiatAccounts[0]
  );
  const transactionDate = FormatTRDate(new Date());
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      // get transaction details
      const fetchTransactionDetails = async () => {
        const data = {
          trn_id: state.transaction.id,
          transaction_nature: "Buy",
        };
        setIsLoading(true);
        const res = await dispatch(getTransactionDetails(data));
        if (res.success) {
          amount.setValue(res.data.crypto_amount);
          remarksInput.setValue(res.data.remarks);

          const fait = fiatAccounts.find(
            (account) => account.id === parseInt(res.data.account_id)
          );

          const crypto = cryptoAccount.find(
            (account) =>
              account.account_number === res.data.receiver_account_number
          );

          // setSelectedCryptoAccount(crypto);
          dispatch(setActiveAccount(crypto));
          setSelectedFiatAccount(fait);

          if (res.data.docs && res.data.docs.length > 0) {
            setDocuments(res.data.docs);
          }
          setIsLoading(false);
        }
      };
      fetchTransactionDetails();
    }
  }, [state, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleManualBuy = async () => {
    if (!selectedFiatAccount || !activeAccount) {
      setError("Please select both fiat and crypto accounts.");
      return;
    }

    if (amount.validate()) {
      return;
    }

    setIsLoading(true);
    setIsOTPModalOpen(true);
    setError("");

    try {
      const data = new FormData();
      data.append("receiver_account_id", activeAccount.id);
      data.append("debit_account_id", selectedFiatAccount.id);
      data.append("amount", amount.value);
      data.append("transaction_date", transactionDate);
      data.append("remarks", remarksInput.value);

      if (supportingDocument) {
        Object.keys(supportingDocument).forEach((key) => {
          supportingDocument[key].forEach((file) => {
            data.append("supporting_documents[]", file);
          });
        });
      }

      let res;
      if (state) {
        data.append("trn_id", state.transaction.id);
        res = await dispatch(UpdateCryptoManualBuy(data));
      } else {
        res = await dispatch(CryptoManualBuy(data));
      }

      if (res.success) {
        setIsOTPModalOpen(true);
        setTransactionId(res.trn_id);
      } else if (!res.success) {
        setIsOTPModalOpen(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        if (res.errors) {
          setError(GetValidationErrors(res.errors));
        } else {
          setError(res.message);
        }
      }
    } catch (error) {
      setError("Something went wrong. Please try again later.");
    }

    // setIsOTPModalOpen(false);
    setIsLoading(false);
  };

  const repeatTransaction = () => {
    setOrder({
      status: "",
    });
    setIsOTPModalOpen(false);
    amount.reset();
    remarksInput.reset();
    setSupportingDocument("");
    setFiles([]);
  };

  const handleViewTransaction = () => {
    setIsOTPModalOpen(false);
    setOrder({
      status: "COMPLETED",
      credit_account: activeAccount,
      debit_account: selectedFiatAccount,
      amount: amount.value,
      transactionDate,
      remarks: remarksInput.value,
    });
  };

  const resetAmounts = () => {
    amount.reset();
  };

  const handleFileChange = (name, selectedFiles) => {
    setSupportingDocument((prevFiles) => {
      const updatedFiles = { ...prevFiles, [name]: selectedFiles };
      if (!selectedFiles.length) {
        delete updatedFiles[name];
      }
      return updatedFiles;
    });
    if (error) {
      setError([]);
    }
  };

  const canSubmit = amount.value !== "" && remarksInput.value !== "";

  return (
    <>
      {order.status === "COMPLETED" ? (
        <div className="max-w-[992px] mx-auto">
          <div className="flex flex-col items-center justify-center md:p-6">
            <div className="md:w-[568px] px-2">
              <OrderComplete
                message=" will be added to
              the below account details."
                repeatTransaction={repeatTransaction}
                coinAmount={
                  order.amount + " " + order.credit_account.currency_code
                }
              >
                <TransactionDetail label="Transaction type" value={"Buy"} />
                <TransactionDetail label="Status" value={"Pending"} />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="Cryptocurrency Amount"
                  value={
                    order.amount + " " + order.credit_account.currency_code
                  }
                />
                <TransactionDetail label="Remarks" value={order.remarks} />
                <hr className="w-full mt-4 border-divider-border" />
                <TransactionDetail
                  label="Transaction Date"
                  value={order.transactionDate}
                />
                <TransactionDetail
                  label="Supporting Documents"
                  value={
                    <ViewSupportingDocument
                      supportingDocument={supportingDocument}
                    />
                  }
                />
              </OrderComplete>
            </div>
          </div>
        </div>
      ) : (
        <main className="pb-4">
          {fiatAccounts.length ? (
            <div className="max-w-4xl mx-auto md:p-6 p-4 bg-dropdown-popup-bg rounded-3xl shadow-box-shadow">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold mb-4 text-body-text">
                  Buy Crypto
                </h1>
                <CancelTransactionModal
                  contextLabel="Cancel Buy Transaction"
                  navigateTo="/crypto"
                >
                  <SmallButton text={"Cancel"} cancel={true} />
                </CancelTransactionModal>
              </div>
              {error && (
                <ErrorMessage message={error} onClose={() => setError("")} />
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                  <Label
                    className="block text-base font-medium text-body-text mb-2"
                    htmlFor="fiatAccount"
                    text="Fiat Account"
                  />
                  <div className="space-y-2">
                    <SelectAccountDropdown
                      title={"Select fiat account"}
                      accounts={fiatAccounts}
                      slectedAccount={selectedFiatAccount}
                      setAccount={(account) => {
                        setSelectedFiatAccount(account);
                        resetAmounts();
                      }}
                    />
                    <FormInputField
                      id="account_balance"
                      label="Fiat Account Balance"
                      type="text"
                      value={
                        FormatBalance(selectedFiatAccount?.balance) || "--"
                      }
                      currency={GetCurrenySymbol(
                        selectedFiatAccount.currency_code,
                        "13",
                        "13"
                      )}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div>
                  <Label
                    className="block text-base font-medium text-body-text mb-2"
                    htmlFor="cryptoAccount"
                    text="Crypto Account"
                  />
                  <div className="space-y-2">
                    <SelectAccountDropdown
                      title={"Select crypto account"}
                      setAccount={(account) => {
                        dispatch(setActiveAccount(account));
                        resetAmounts();
                      }}
                      accounts={cryptoAccount}
                      slectedAccount={activeAccount}
                    />
                    <FormInputField
                      id="crypto_account_balance"
                      label="Crypto Account Balance"
                      type="text"
                      value={
                        FormatBalance(activeAccount?.balance, true) || "--"
                      }
                      currency={GetCurrenySymbol(
                        activeAccount.currency_code,
                        "13",
                        "13"
                      )}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-2 grid-cols-1 gap-2 mt-3">
                <div className="w-full">
                  <FormInputField
                    label={`Crypto ${
                      activeAccount?.currency_code || ""
                    } coins to buy`}
                    name="amount"
                    id="amount"
                    type="number"
                    required={true}
                    placeholder={`Enter amount in ${activeAccount.currency_code}`}
                    currency={GetCurrenySymbol(
                      activeAccount.currency_code,
                      "13",
                      "13"
                    )}
                    {...amount}
                  />
                  {activeAccount.min_amount && activeAccount.max_amount && (
                    <p className="text-xs text-body-text mx-2 my-1">
                      {`Limits: (min: ${activeAccount.min_amount} , max: ${activeAccount.max_amount})`}
                    </p>
                  )}
                </div>

                <div className="flex space-x-1 w-full max-h-14">
                  <FormInputField
                    label="Transaction Date"
                    name="transaction_date"
                    id="transaction_date"
                    type="text"
                    value={transactionDate}
                    onChange={() => {}}
                    readOnly
                  />
                </div>
              </div>

              <div className="grid sm:grid-cols-12 mt-3">
                <div className="col-span-6">
                  <FormTextAreaField
                    label="Remarks"
                    name="remarks"
                    id="remarks"
                    rows="3"
                    cols="33"
                    placeholder="Please provide relvant remarks for the transaction."
                    {...remarksInput}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                <div>
                  <MultipleFileUpload
                    name="supporting_documents"
                    id="supporting_documents"
                    title={"Supporting document"}
                    onChange={handleFileChange}
                    isMultiple={true}
                    maxCount={5}
                    mandatory={true}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
                <PreviousDocs
                  documents={documents}
                  handleDeleteDocument={handleDeleteDocument}
                />
              </div>

              <div className="grid grid-cols-2 gap-4 sm:grid-cols-6 mt-3">
                <PrimaryButton
                  text="Submit"
                  onClick={handleManualBuy}
                  disabled={!canSubmit}
                />
              </div>
            </div>
          ) : (
            <div className="max-w-4xl md:mx-auto space-y-20 mx-2 min-h-[80vh] p-4 md:p-6 bg-dropdown-popup-bg rounded-3xl shadow-box-shadow">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold mb-4 text-body-text">
                  Buy Crypto
                </h1>
                <CancelTransactionModal
                  contextLabel="Cancel Buy Transaction"
                  navigateTo="/crypto"
                >
                  <SmallButton text={"Cancel"} cancel={true} />
                </CancelTransactionModal>
              </div>
              <div className="flex items-center justify-center">
                <EmptyCryptoTransactions />
              </div>
            </div>
          )}
        </main>
      )}

      {isOTPModalOpen && (
        <TransactionOTPModal
          isLoading={isLoading}
          setisLoading={setIsLoading}
          TrnId={transactionId}
          setTrnId={setTransactionId}
          isModalOpen={isOTPModalOpen}
          setIsModalOpen={setIsOTPModalOpen}
          handleTransaction={handleManualBuy}
          reduxaction={verifyTROTP}
          navigateUrl={"/crypto"}
          handleViewTransaction={handleViewTransaction}
        />
      )}
    </>
  );
};

export default ManualBuy;
