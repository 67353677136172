import React from "react";
import { Bank_Name } from "../../../../../config";
import { DownArrowIcon } from "../../../../Icons";

const ActiveAccount = ({ activeAccount }) => {
  return (
    <>
      {activeAccount && (
        <div className="flex p-[6px] pr-4 max-w-[338px] max-[360px]:max-w[300px] justify-between items-center bg-active-dropdown rounded-[100px] cursor-pointer hover:bg-hover-state-bg">
          <div className="flex items-center pr-12">
            <img
              src={activeAccount?.logo}
              alt="flag"
              className="w-10 h-10 rounded-full"
            />
            <div className="px-2">
              <p className="text-primary-item text-sm font-medium leading-5 tracking-[0.07px] ">
                {activeAccount.account_number}
              </p>
              <p className="text-primary-item font-normal text-sm leading-5 tracking-[0.07px] whitespace-nowrap">
                {activeAccount.currency_code} · {Bank_Name}
              </p>
            </div>
          </div>
          <DownArrowIcon className="h-5 w-5 pr-5" />
        </div>
      )}
    </>
  );
};

export default ActiveAccount;
