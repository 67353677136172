import React, { useState } from "react";
import { SearchIcons } from "../../Icons";
import ListItem from "../../Modals/AddAccount/partials/ListItem";
import CommonButton from "../../Buttons/CommonButton";
import EmptyBeneficiaryIcon from "../../Icons/EmptyBeneficiaryIcon";

const ClientAccountsList = ({
  clientsAccounts,
  CloseAddAcccount,
  setCloseAddAccount,
  handleSelectAccount,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  // const filteredAccounts = []
  const filteredAccounts = clientsAccounts.filter((account) =>
    account.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div>
        <h2 className="pb-3 text-sm font-medium text-body-text">
          Choose your account
        </h2>

        <div className="w-full px-3 py-1 bg-secondary-icon-bg dark:bg-[#35322A] rounded-full flex justify-start items-center">
          <SearchIcons className="w-6 h-6 relative" />
          <input
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder="Search"
            className="w-full text-paragraph-text text-sm font-normal bg-transparent border-none focus:ring-0 tracking-tight"
          />
        </div>

        {filteredAccounts.length ? (
          <>
            {filteredAccounts.map((account) => (
              <div key={account.id} onClick={() => setCloseAddAccount(account)}>
                <ListItem
                  title={account.name}
                  subTitle="CIB account"
                  selectedItem={CloseAddAcccount}
                  itemId={account.id}
                />
              </div>
            ))}
            <div className={`mt-8 sticky z-50 bottom-9`}>
              <CommonButton
                onClick={handleSelectAccount}
                disabled={CloseAddAcccount.id ? false : true}
              >
                Continue
              </CommonButton>
            </div>
          </>
        ) : (
          <div className="flex flex-col text-body-text items-center justify-center py-6">
            <EmptyBeneficiaryIcon className="w-40 h-40" />
            <p>No account found</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientAccountsList;
